.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.5s forwards;
}

/*  */
.modal-content-payment {
    max-width: 1400px;
    width: 100%;
    position: relative;
    transform: translateY(-20px);
    animation: slideIn 0.5s forwards;
}

.model-body {
    border: 1px solid #404040;
    border-radius: 6px;
}

/* For payment scroll */
@media (max-width: 991px) {
    .modal-content-payment {
        overflow: auto;
        margin: 14px;
        width: 90%;
        height: 97%;
    }


    .model-body {
        border: none;
        border-radius: 6px;
    }
}

.close-button {
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-button-payment {
    font-size: 24px;
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
}

@media (max-width: 991px) {

    .close-button-payment {
        font-size: 24px;
        position: absolute;
        top: 28px;
        right: 8px;
        cursor: pointer;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
    }

    to {
        transform: translateY(0);
    }
}


/* Generate song Failure Popup */
/* Modal Background */
.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000cf;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

/* Dialog Content */
.dialog-content {
    /* background: linear-gradient(45deg, #77a19f, #83a0a4, #ececec, #26757e); */
    background: linear-gradient(90deg, #a2a99b, #85bbb4, #2b5350);
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    transform-origin: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Animation for Opening */
.dialog-open {
    animation: dialogOpen 0.3s ease-out forwards;
}

/* Animation for Closing */
.dialog-close {
    animation: dialogClose 0.3s ease-in forwards;
}

/* Keyframes for Opening Animation */
@keyframes dialogOpen {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.clg-btn {
    background: #2b2b2b;
    border: 1px solid #3a3737;
    padding: 4px 38px;
    border-radius: 8px;
    color: #ffffff;
}

/* Keyframes for Closing Animation */
@keyframes dialogClose {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0.8);
    }
}

.bottom-option-model {
    width: 148px;
}

.bottom-option-model-bar {
    background: linear-gradient(45deg, #a1e27a, #52c6b7, #317c75);
}